<template>
  <div class="partList">
    <nav-bar title="配件列表"></nav-bar>
    <div class="resize" id="resize">
      <img :src="img.imagePath" alt="" id="img" ref="img" />
      <div
        v-for="(item, index) in hotspots"
        :key="index"
        class="spots"
        :style="{
          left: item.hsX * scale + left + resizeType + 0 + 'px',
          top: item.hsY * scale + top + resizeType + 'px',
          width: (35 / originWidth) * changeWidth + 'px',
          height: (12 / originHeight) * changeHeight + 'px',
        }"
        @click="choicePart(item)"
      ></div>
    </div>
    <div class="note flex">
      <div>提示：点击图示配件序号，可快速查找对应配件</div>
    </div>
    <div class="partDetail">
      <div class="findNum">
        已为您找到
        <span class="num">{{ (part_detail.length || 0) + "件" }}</span>
        匹配的配件
      </div>
      <div
        v-for="(item, index) in part_detail"
        :key="index"
        class="partItem"
        @click="toPartDetail(item)"
      >
        <div class="title van-hairline--bottom">
          {{ item.oeGoodsName || item.label }}
        </div>
        <div class="flex">
          <div>位置</div>
          <div style="color: #fe7344">{{ item.pnum }}</div>
        </div>
        <div class="flex">
          <div>零件OE号</div>
          <div>{{ item.pid }}</div>
        </div>
        <div class="flex">
          <div>名称</div>
          <div>{{ item.label }}</div>
        </div>
        <div class="flex">
          <div>参考价格</div>
          <div>{{ item.price }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import { getLlqPartPrice } from "@/api";
import { Dialog } from "vant";
export default {
  name: "partList",
  components: {
    NavBar,
  },
  data() {
    return {
      hotspots: [], //标点
      img: {}, //图片
      part_detail: {}, //配件列表
      savePartList: {}, //存放配件列表
      originWidth: "", //初始宽度
      originHeight: "", //初始高度
      changeWidth: "", //初始宽度
      changeHeight: "", //初始高度
      scale: 1, //缩放比例
      left: 0, //左距离
      top: 0, //上距离
      resizeType: 0, //移动距离
    };
  },
  methods: {
    choicePart(item) {
      console.log(item);
      var arr = [];
      this.savePartList.map((val) => {
        if (val.pnum == item.hsKey) {
          arr.push(val);
        }
      });
      this.part_detail = [...arr];
    },
    //查询EPC零件4s店参考价
    getPrice(pidList, partDetail) {
      console.log(partDetail);
      if (pidList.length == 1 && pidList[0] == "") {
        this.part_detail = partDetail.map((val, index) => {
          return { ...val, price: "" };
        });
        this.savePartList = [...this.part_detail];
        return;
      }
      getLlqPartPrice({
        pid_list: pidList.join(","),
        brandCode: sessionStorage.getItem("brandCode"),
      }).then((res) => {
        console.log(res.data.data.length);
        if (res.data.data) {
          this.part_detail = partDetail.map((val, index) => {
            return { ...val, price: res.data.data[val.pid].sale_price };
          });
          this.savePartList = [...this.part_detail];
        }
      });
    },
    //点击配件
    toPartDetail(item) {
      if (item.gooGoodsId) {
        this.$router.push({
          name: "partDetail",
          params: { goodsId: item.gooGoodsId },
        });
      } else {
        Dialog.alert({
          message: "该零件暂无供应销售",
        });
      }
    },
    setResize() {
      var that = this;
      that.$nextTick(() => {
        var img = document.getElementById("img");
        img.onload = () => {
          console.log(img);
          console.log(img.clientWidth, img.width);
          that.originWidth = img.clientWidth;
          that.originHeight = img.clientHeight;
          // that.changeWidth = img.clientWidth;
          // that.changeHeight = img.clientHeight;
          console.log(document.documentElement.clientWidth, that.originWidth);
          that.scale = document.documentElement.clientWidth / that.originWidth;
          console.log(that.scale);
          // that.scale = Number(document.documentElement.clientWidth/that.originWidth).toFixed(2)
          img.style.width = "100%";
          that.originWidth = img.clientWidth;
          that.originHeight = img.clientHeight;
          that.changeWidth = img.clientWidth;
          that.changeHeight = img.clientHeight;
          var data = JSON.parse(sessionStorage.getItem("partDetail"));
          that.hotspots = [...data.hotspots];
          console.log(that.hotspots, "dian ");
          var handler = function (e) {
            e.preventDefault();
          };
          var reg = new RegExp("px");
          var oL = 0,
            imgTop = 0,
            imgOftp = 0,
            oT = 0;
          var sX,
            eX,
            sY,
            eY,
            scale,
            oLeft,
            oTop,
            status = false,
            length = 0;
          var maxW = document.body.clientWidth - img.offsetWidth;
          var maxH = document.getElementById("img").height;
          img.addEventListener("touchstart", function (e) {
            var touch = e.targetTouches;
            var touch1 = touch[0]; // 第一根手指touch事件
            var touch2 = touch[1]; // 第二根手指touch事件
            if (touch.length == 2) {
              length = 2;
              // 放缩图片x轴初始
              sX = Math.abs(touch1.pageX - touch2.pageX);
              sY = Math.abs(touch1.pageY - touch2.pageY);
              status = false;
            } else {
              length = 1;
              var ev = e || window.event;
              var target = ev.targetTouches;
              var touch = target[0];
              console.log(target);
              oL = touch.clientX - img.offsetLeft;
              oT = touch.clientY - img.offsetTop;
            }
            status = false;
            // document.addEventListener("touchmove", handler, { passive: false }); //阻止默认事件
          });
          //触摸中的，位置记录
          img.addEventListener("touchmove", function (e) {
            var touch = e.targetTouches;
            var touch1 = touch[0]; // 第一根手指touch事件
            var touch2 = touch[1]; // 第二根手指touch事件
            if (touch.length == 2) {
              // 放缩图片x轴
              eX = Math.abs(touch1.pageX - touch2.pageX);
              eY = Math.abs(touch1.pageY - touch2.pageY);
              scale = Math.abs(eX - sX) > Math.abs(eY - sY) ? eX - sX : eY - sY;
              status = true;
            } else {
              imgTop = img.style.top || "0";
              imgOftp = imgTop.replace(reg, "");
              var ev = e || window.event;
              var touch = ev.targetTouches[0];
              oLeft = touch.clientX - oL;
              oTop = touch.clientY - oT;
              status = true;
              //   if (oLeft < maxW - 300 || oLeft >= maxW + 300) {
              //     return;
              //   }
              //   if (oTop < -maxH + 100 || oTop > maxH - imgOftp - 100) {
              //     return;
              //   }
            }
          });
          img.addEventListener("touchend", function (e) {
            if (length == 2 && status) {
              console.log(scale);
              img.style.width = img.clientWidth + scale + "px";
              img.style.height = img.changeHeight + scale + "px";
              var newScale = (img.clientWidth + scale) / 1050;
              that.changeWidth = img.clientWidth + scale;
              that.changeHeight = img.clientHeight + scale;
              that.resizeType = newScale - that.scale;
              console.log(newScale, that.scale);
              that.scale = newScale;
            } else if (length == 1 && status) {
              img.style.left = oLeft + "px";
              img.style.top = oTop + "px";
              that.left = oLeft;
              that.top = oTop;
            }
          });
        };
      });
    },
    flat(arr) {
      return [].concat(
        ...arr.map((item) => [].concat(item, ...flat(item.children)))
      );
    },
  },
  mounted() {
    var data = JSON.parse(sessionStorage.getItem("partDetail"));
    this.img = data.img;
    var partDetail = [],
      pidList = [];
    data.part_detail.flat().map((val) => {
      if (val.colorvalue) {
        partDetail.push(val);
        pidList.push(val.pid);
      }
    });
    console.log(pidList);
    this.getPrice(pidList, partDetail);
    this.setResize(); //移动缩放
  },
};
</script>

<style lang="less" scoped>
.partList {
  // padding-top: 55px;
  .note {
    padding: 0 15px;
    height: 42px;
  }
  .findNum {
    padding-top: 7px;
    color: #767676;
    font-size: 13px;
    .num {
      color: #464646;
      font-weight: bold;
    }
  }
  .partDetail {
    // overflow-y: scroll;
    background: #f8f8f8;
    padding: 0 15px 15px;
    .partItem {
      margin: 10px 0;
      background: #fff;
      padding: 0 10px;
      .title {
        font-size: 15px;
        color: #161616;
        font-weight: bold;
        padding: 15px 0;
      }
      .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 7px 0;
        font-size: 12px;
        div {
          &:first-child {
            color: #767676;
          }
          color: #464646;
          font-weight: bold;
        }
      }
    }
  }
  .resize {
    width: 100%;
    height: 350px;
    overflow: hidden;
    position: relative;
    border-bottom: 1px solid #ccc;
    img {
      position: absolute;
      -moz-user-select: none; /*火狐*/
      -webkit-user-select: none; /*webkit浏览器*/
      -ms-user-select: none; /*IE10*/
      -khtml-user-select: none; /*早期浏览器*/
      user-select: none;
    }
    .spots {
      position: absolute;
      width: 35px;
      height: 15px;
      border: 1px solid #fff;
    }
  }
}
</style>